export default [{
  title: 'Dashboard',
  items: [{
    title: 'Alerts',
    to: '/alerts',
    htmlBefore: '<i class="fa fa-exclamation-triangle"></i>',
    rule: (can) => can('list', 'Alert')
  }, {
    title: 'Home Visits',
    to: '/home-visits',
    htmlBefore: '<i class="fa-solid fa-house"></i>',
    rule: (can) => can('list', 'HomeVisit')
  }, {
    title: 'Alert Log',
    to: '/log/alerts',
    htmlBefore: '<i class="fa fa-history"></i>',
    rule: (can) => can('list', 'LogAlert')
  }, {
    title: 'Floor Plans',
    to: '/floorplans',
    htmlBefore: '<i class="fa fa-map"></i>',
    rule: (can) => can('list', 'FloorPlan')
  }]
}, {
  title: 'System Health',
  items: [{
    title: 'Disconnected Gateways',
    to: '/health/disconnected-gateways',
    htmlBefore: '<i class="fa fa-microchip"></i>',
    rule: (can, flags) => can('read', 'Report', 'metrics')
  }, {
    title: 'Tag Replacement Timeline',
    to: '/reports/tags-replacement',
    htmlBefore: '<i class="fa fa-rss"></i>',
    rule: (can, flags) => can('read', 'Report', 'replacement')
  }]
}, {
  title: 'Analytics',
  items: [{
    title: 'Incidents',
    to: '/analytics/incidents',
    htmlBefore: '<i class="fas fa-chart-bar"></i>',
    rule: (can, flags) => can('read', 'Report', 'analytics')
  }, {
    title: 'Response Times',
    to: '/analytics/response-times',
    htmlBefore: '<i class="fas fa-stopwatch"></i>',
    rule: (can, flags) => can('read', 'Report', 'analytics')
  }]
}, {
  title: 'Devices',
  items: [{
    title: 'Tags',
    to: '/tags',
    htmlBefore: '<i class="fa fa-rss"></i>',
    rule: (can) => can('list', 'Tag')
  }, {
    title: 'Gateways',
    to: '/gateways',
    htmlBefore: '<i class="fa fa-microchip"></i>',
    rule: (can, flags) => can('list', 'Gateway') && !flags.outdoor
  }, {
    title: 'Indoor Gateways',
    to: '/gateways',
    htmlBefore: '<i class="fa fa-microchip"></i>',
    rule: (can, flags) => can('list', 'Gateway') && flags.outdoor
  }, {
    title: 'Outdoor Gateways',
    htmlBefore: '<i class="fa fa-broadcast-tower"></i>',
    items: [{
      title: 'Nodes',
      to: '/outdoor/nodes'
    }, {
      title: 'Bridges',
      to: '/outdoor/bridges'
    }],
    rule: (can, flags) => can('list', 'Gateway') && flags.outdoor
  }, {
    title: 'Consoles',
    to: '/consoles',
    htmlBefore: '<i class="fa fa-desktop"></i>',
    rule: (can) => can('list', 'Console')
  }, {
    title: 'Distribution Stations',
    to: '/distribution-stations',
    htmlBefore: '<i class="fa fa-id-badge"></i>',
    rule: (can) => can('list', 'DistributionStation')
  }]
}, {
  title: 'Settings',
  items: [{
    title: 'People',
    to: '/people',
    htmlBefore: '<i class="fa fa-female"></i>',
    rule: (can) => can('list', 'Person')
  }, {
    title: 'Subscriptions',
    to: '/subscriptions',
    htmlBefore: '<i class="fa fa-phone-volume"></i>',
    rule: (can) => can('list', 'Subscription')
  }, {
    title: 'Zones',
    to: '/zones',
    htmlBefore: '<i class="fa fa-map-marker-alt"></i>',
    rule: (can) => can('list', 'Zone')
  }, {
    title: 'Users',
    to: '/users',
    htmlBefore: '<i class="fa fa-user"></i>',
    rule: (can) => can('list', 'User')
  }, {
    title: 'Roles',
    to: '/roles',
    htmlBefore: '<i class="fa fa-user-friends"></i>',
    rule: (can) => can('list', 'Role')
  }, {
    title: 'API Keys',
    to: '/keys',
    htmlBefore: '<i class="fa fa-key"></i>',
    rule: (can) => can('list', 'User')
  }]
}, {
  title: 'Operations',
  items: [{
    title: 'Tag Scratch List',
    to: '/operations/tags',
    htmlBefore: '<i class="fa fa-box-open"></i>',
    htmlAfter: '',
    rule: (can) => can('list', 'Tag') && can('update', 'Tag')
  }, {
    title: 'Gateway Scratch List',
    to: '/operations/gateways',
    htmlBefore: '<i class="fa fa-dolly"></i>',
    htmlAfter: '',
    rule: (can) => can('list', 'Gateway') && can('update', 'Gateway')
  }, {
    title: 'Orders',
    to: '/operations/orders',
    htmlBefore: '<i class="fa fa-shopping-cart"></i>',
    htmlAfter: '',
    rule: (can) => can('list', 'Order') && can('update', 'Order')
  }, {
    title: 'Orders Address Book',
    to: '/operations/orders/recipients',
    htmlBefore: '<i class="fa fa-address-card"></i>',
    htmlAfter: '',
    rule: (can) => can('list', 'Order') && can('update', 'Order')
  }]
}, {
  title: 'Reports',
  // action: {
  //   title: 'Edit',
  //   to: '/reports',
  //   rule: (can) => can('create', 'Report')
  // },
  items: [{
    title: 'Tags',
    htmlBefore: '<i class="fa fa-rss"></i>',
    rule: (can) => can('read', 'Report', 'tags'),
    items: [{
      title: 'Missing Tags',
      to: '/reports/tags-not-seen'
    }]
  }]
}, {
  title: 'Logs',
  items: [{
    title: 'Sync Log',
    to: '/log/sync',
    htmlBefore: '<i class="fa fa-sync"></i>',
    rule: (can) => can('list', 'LogSync')
  }, {
    title: 'Change Log',
    to: '/log/audit',
    htmlBefore: '<i class="fa fa-clipboard-list"></i>',
    rule: (can) => can('list', 'LogAudit')
  }, {
    title: 'Batch Operations Log',
    to: '/log/batch',
    htmlBefore: '<i class="fa fa-list"></i>',
    htmlAfter: '',
    rule: (can) => can('list', 'LogBatch')
  }]
}]
